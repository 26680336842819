import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
import useUser from 'stores/user';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    const user = useUser(state => state.user);
    const isLogged = !_.isEmpty(user);
    const roleRoute = user.role === 'Admin' ? "/users" : "/users";

    // restricted = true meaning restricted route when logged in
    return (
        <Route {...rest} render={props => (
            isLogged && restricted ?
                <Redirect to={roleRoute} />
                : <Component {...props} />
        )} />
    );
};



export default PublicRoute;