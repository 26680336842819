import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input";
import Presentation from "components/elements/Presentation/Presentation";
import './RecoverPasswordComponent.scss';
import ArrowBack from "assets/icons/arrowBack";

const RecoverPasswordComponent = (props) => {
    const { loading, submit, error, history } = props;

    const formik = useFormik({
        initialValues: { email: "" },
        validationSchema:
            yup.object().shape({
                email: yup.string().email("Invalid email format").required("Email is a required field"),
            }),
        onSubmit: ({ email }) => submit(email)
    })

    return (
        <div className="RecoverPassword">
            <Presentation />
            <div className="formContainer">
                <div className="form">
                    <div className="arrow-back" onClick={() => history.push('/Login')}>
                        <span>
                            <ArrowBack />Back to login
                        </span>
                    </div>
                    <div className="title">
                        Recover account
                    </div>
                    <div className="subtitle">
                        Enter your email. We will send a link to recover your account.
                    </div>

                    <Input
                        id="email"
                        placeholder="email"
                        formik={formik}
                        type="text"
                        label="Email*"
                        className="formControl"
                    />

                    <Button
                        loading={loading}
                        type="submit"
                        className="button"
                        onClick={formik.handleSubmit}>
                        Send email
                    </Button>
                </div>
            </div>
        </div >
    );
}

export default RecoverPasswordComponent;

