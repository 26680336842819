import React from 'react'
import { Table as AntdTable, Empty as AntdEmpty } from 'antd';
import { IoArrowBackCircleOutline, IoArrowForwardCircleOutline } from 'react-icons/io5'
import "antd/dist/antd.css";
import './Table.scss';

const Table = (props) => {
    const { data, columns, total, loading } = props;
    const { Column } = Table;
    const maxLines = Math.trunc((window.innerHeight - 275) / 49);
    const maxWidth = window.innerWidth - 310;

    const pagination = {
        pageSize: maxLines,
        hideOnSinglePage: true,
        itemRender: (page, type) => {
            if (type === 'prev') {
                if (page === 0) return <></>
                else return (
                    <div className="pagination-icon">
                        <IoArrowBackCircleOutline />
                    </div>
                )
            } else if (type === 'next') {
                return (
                    <div className="pagination-icon">
                        <IoArrowForwardCircleOutline />
                    </div>
                )
            } else if (type === 'page') {
                return (
                    <div >
                        {page}
                    </div>
                )
            }
        }
    }

    return (
        <div className="table-wrapper">
            <AntdTable
                dataSource={data}
                rowClassName="table_row"
                style={{ maxWidth: maxWidth }}
                pagination={pagination}
                loading={loading || !data || data.length === 0}
                scroll={{ x: 'max-content' }}
            >
                {columns.map((column) => {
                    return (
                        <Column
                            title={column.title}
                            dataIndex={column.dataIndex}
                            key={column.key}
                            width={column.width}
                        />
                    )
                })}
            </AntdTable>
        </div>
    )
}
export default Table;