import React, { useState } from "react";
import LoginComponent from "../components/LoginComponent";
import { loginService } from "services/user"
import useUser from 'stores/user';


const Login = (props) => {
    const { history } = props;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const updateUser = useUser(state => state.updateUser);

    const submit = async (values) => {

        const data = {
            email: values.email,
            password: values.password
        }

        try {
            setLoading(true);
            const response = await loginService(data);
            updateUser(response);
            history.push("/users");
        } catch (error) {
            console.log('error', error);
            setError(true);
            setLoading(false);
        }
        finally {
            setLoading(false);
        }
    };

    return (<LoginComponent
        {...props}
        submit={submit}
        loading={loading}
        error={error}
        history={history}
    />);
}


export default Login;