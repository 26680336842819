import ToastrContent from "components/elements/Toastr/Toastr";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { blockerUserService, deleteUserService, getUserInfoService } from "services/user";
import UsersComponent from '../components/UsersComponent';

const Users = (props) => {
    const [loading, setLoading] = useState(false)
    const [usersData, setUsersData] = useState()

    useEffect(() => {
        getUsersData()
    }, [])

    const getUsersData = async () => {
        try {
            setLoading(true)
            const data = await getUserInfoService()
            setUsersData(data)
        }
        catch (e) {
            console.log('error updating', e);
        }
        finally {
            setLoading(false)
        }
    }

    const blockUser = async (id, isBlocked) => {
        try {
            setLoading(true)
            const res = await blockerUserService(id, isBlocked)

            toast(<ToastrContent type='success' title={'Success'} message={`The user has been ${!isBlocked ? 'unblocked' : 'blocked'}`} />, {
                progressClassName: 'toastr-progress-bar success',
            })
            getUsersData()
        }
        catch (e) {
            console.log('error updating', e);
            toast(<ToastrContent type='danger' title={'Error'} message={'Try again later'} />, {
                progressClassName: 'toastr-progress-bar danger',
            })
        }
        finally {
            setLoading(false)
        }
    }

    const deleteUser = async (id) => {
        try {
            setLoading(true)
            const res = await deleteUserService(id)

            toast(<ToastrContent type='success' title={'Success'} message={`The user has been deleted'}`} />, {
                progressClassName: 'toastr-progress-bar success',
            })
            getUsersData()
        }
        catch (e) {
            console.log('error updating', e);
            toast(<ToastrContent type='danger' title={'Error'} message={'Try again later'} />, {
                progressClassName: 'toastr-progress-bar danger',
            })
        }
        finally {
            setLoading(false)
        }
    }

    return <UsersComponent
        {...props}
        {...{
            usersData,
            blockUser,
            deleteUser,
            loading
        }}
    />
}

export default Users;
