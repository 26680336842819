import React from "react";
import "./AuthLayout.scss";

const AuthLayout = (props) => {
    const { children } = props;

    return (
        <div className="auth-layout">
            <div className="auth-layout__card">
                <img className="app-logo" src={require('assets/images/ptg-logo.png')} alt="" />
                {children}
            </div>
        </div>
    );
};

export default AuthLayout;