import React from 'react';

const UserIcon = ({ color }) => {

    return (
        <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5374 7.40984C15.5012 7.32153 15.4631 7.23372 15.423 7.14741C14.4264 4.99833 12.2156 3.67465 9.84928 3.67465H6.15072C3.78286 3.67465 1.57356 4.99883 0.57754 7.14741C0.537399 7.23372 0.499265 7.32153 0.462636 7.40984C0.15687 8.15147 -0.000316054 8.94591 4.77107e-07 9.7481C4.77107e-07 12.9775 2.53194 15.6916 5.96456 16.4749C6.04083 16.4924 6.11509 16.5115 6.19437 16.525C8.29329 16.8733 8.10913 17.7017 8.37558 18.0303C8.37558 18.0303 11.7505 15.9545 12.5734 15.4553C14.6447 14.2008 16 12.1114 16 9.7491C16.0004 8.94658 15.8433 8.15178 15.5374 7.40984Z" fill="#C481E2" />
            <path d="M3.87939 11.3498C6.55936 12.1815 9.42849 12.1815 12.1085 11.3498" stroke="#6000C6" stroke-width="0.35124" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.42138 10.9112C7.24359 10.9112 7.91013 10.2447 7.91013 9.42244C7.91013 8.60023 7.24359 7.93369 6.42138 7.93369C5.59916 7.93369 4.93262 8.60023 4.93262 9.42244C4.93262 10.2447 5.59916 10.9112 6.42138 10.9112Z" fill="#F6F2F1" />
            <path d="M9.56493 10.9112C10.3871 10.9112 11.0537 10.2447 11.0537 9.42244C11.0537 8.60023 10.3871 7.93369 9.56493 7.93369C8.74271 7.93369 8.07617 8.60023 8.07617 9.42244C8.07617 10.2447 8.74271 10.9112 9.56493 10.9112Z" fill="#F6F2F1" />
            <path d="M9.56475 10.4145C10.1126 10.4145 10.5568 9.97032 10.5568 9.42245C10.5568 8.87459 10.1126 8.43045 9.56475 8.43045C9.01689 8.43045 8.57275 8.87459 8.57275 9.42245C8.57275 9.97032 9.01689 10.4145 9.56475 10.4145Z" fill="#6000C6" />
            <path d="M6.42559 10.4145C6.97346 10.4145 7.4176 9.97032 7.4176 9.42245C7.4176 8.87459 6.97346 8.43045 6.42559 8.43045C5.87773 8.43045 5.43359 8.87459 5.43359 9.42245C5.43359 9.97032 5.87773 10.4145 6.42559 10.4145Z" fill="#6000C6" />
            <path d="M7.6999 3.39267C6.91764 3.43783 5.886 3.29583 5.21111 2.5522C4.73142 2.02434 4.55078 0.00320138 4.55078 0.00320138C4.55078 0.00320138 6.37874 -0.0936405 7.14294 0.777937C7.51977 1.20745 7.57747 1.81058 7.65725 2.36404C7.6708 2.44833 7.82334 3.38564 7.6999 3.39267Z" fill="#00525C" />
            <path d="M8.11863 3.39267C8.90089 3.43783 9.93204 3.29583 10.6074 2.5522C11.0871 2.02434 11.2678 0.00320138 11.2678 0.00320138C11.2678 0.00320138 9.4393 -0.0936405 8.6756 0.777937C8.29877 1.20745 8.24107 1.81058 8.16128 2.36404C8.15125 2.44833 7.9962 3.38564 8.11863 3.39267Z" fill="#00525C" />
        </svg>

    )
}

export default UserIcon;