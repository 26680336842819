import React, { useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSignOutAlt, faUserLock, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from "formik";
import { FiSearch, FiLogOut } from 'react-icons/fi'
import OutsideClickHandler from 'react-outside-click-handler';
import useUser from 'stores/user';
import "./DashboardLayout.scss";
import Input from "components/elements/Input/Input";
import * as yup from "yup";
import UserIcon from "assets/icons/UserIcon";
import UsersTree from "assets/icons/UsersTree";

const DashboardLayout = (props) => {
    const { history, handleInputvalue } = props
    const user = useUser(state => state.user);
    const setUser = useUser(state => state.setUser);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const formik = useFormik({
        initialValues: { search: "" },
        validationSchema:
            yup.object().shape({
                search: yup.string(),
            }),
    })

    const adminRoutes = [
        {
            icon: <UsersTree />,
            label: 'Users',
            routeLink: '/users',
        },
    ];

    const routes = [...adminRoutes];

    const { children, location: { pathname } } = props;
    const currentPath = pathname;

    const username = `Admin`;

    const logout = () => {
        setUser({});
        localStorage.clear();
    }

    const handleInput = useMemo(() => {
        handleInputvalue && handleInputvalue(formik.values["search"])
    }, [formik.values["search"]])

    return (
        <div className="dashboard-layout">
            <header className="header-wrapper">
                <div className="logo">
                    <img src={require('assets/images/turnip-logo.png')} alt="logo-full" onClick={() => history.push("/")} />
                </div>
                <div className="right-side-wrapper">
                    <Input
                        id="search"
                        placeholder="search"
                        formik={formik}
                        type="text"
                        icon={<FiSearch size={22} color="c482e3" />}
                    />
                    <div className="logged-user-info">
                        <OutsideClickHandler onOutsideClick={(e) => setIsDropdownOpen(false)}>
                            <div className="logged-user-info__content" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                                <div className="userIconLogout">
                                    <UserIcon />
                                </div>
                                <span title={username}>{username}</span>
                                {isDropdownOpen ?
                                    <FontAwesomeIcon icon={faChevronUp} /> :
                                    <FontAwesomeIcon icon={faChevronDown} />}
                            </div>

                            {
                                isDropdownOpen &&
                                <div className="logged-user-info__options">
                                    <div className="option" onClick={() => logout()}>
                                        <FiLogOut icon={faSignOutAlt} />
                                        <span>Sign out</span>
                                    </div>
                                </div>

                            }
                        </OutsideClickHandler>
                    </div>
                </div>
            </header>
            <div className="sidebar">
                {
                    routes.map((route, i) => (
                        <Link
                            to={route.routeLink}
                            key={i}
                            className={`sidebar-item ${route.routeLink.includes(currentPath) ? "active" : null}`}>
                            <div className="sidebar-icon">{route.icon}</div>
                            <div className="sidebar-label">{route.label}</div>
                        </Link>
                    ))
                }
            </div>

            <div className="content">
                <div className="content-children">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default withRouter(DashboardLayout);