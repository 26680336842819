import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import history from './services/history';
import { MdClose } from 'react-icons/md';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Material UI
import { materialTheme } from "designSystem/materialTheme";
import { ThemeProvider } from "@material-ui/core";

// Router Components
import PublicRoute from 'components/elements/PublicRoute/PublicRoute';
import PrivateRoute from 'components/elements/PrivateRoute/PrivateRoute';

// Auth Routes
import Login from './components/Login/containers/Login';
import Register from './components/Register/containers/Register';

// Dashboard (all users) Routes
import Users from './components/Users/containers/Users';

// Admin Routes
import CustomPage from './components/Admin/CustomPage/containers/CustomPage';
import RecoverPassword from "components/RecoverPassword/containers/RecoverPassword";

function App() {
	const CloseButton = () => <MdClose className="icon toastr-close" />;

	return (
		<ThemeProvider theme={materialTheme}>
			<Router history={history}>
				<div className="toastr-div">
					<ToastContainer toastClassName="toastr-wrapper" autoClose={5000} hideProgressBar={false} pauseOnHover closeButton={<CloseButton />} />
				</div>
				<div className="App">
					<Switch>
						<PublicRoute restricted component={Login} path="/" exact />
						<PublicRoute restricted component={Login} path="/login" />
						<PublicRoute restricted component={Register} path="/register" />
						<PublicRoute restricted component={RecoverPassword} path="/forgot-password" />

						{/* Common private Routes */}

						{/* Admin Routes */}
						<PrivateRoute role="Admin" component={Users} path="/users" />
					</Switch>
				</div>
			</Router>
		</ThemeProvider>
	);
}

export default App;
