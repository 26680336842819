import React from 'react';

const TrashIcon = ({ color }) => {
    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.9678 9.84375L7.03027 9.84376" stroke={color || "#1F383B"} stroke-width="3.6" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.4688 4.21875H29.5312" stroke={color || "#1F383B"} stroke-width="3.6" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M35.1562 9.84375V36.5625C35.1562 36.9355 35.0081 37.2931 34.7444 37.5569C34.4806 37.8206 34.123 37.9688 33.75 37.9688H11.25C10.877 37.9688 10.5194 37.8206 10.2556 37.5569C9.99191 37.2931 9.84375 36.9355 9.84375 36.5625V9.84375" stroke={color || "#1F383B"} stroke-width="3.6" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default TrashIcon;