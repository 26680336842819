import React from "react";
import "./Input.scss";

const InputComponent = (props) => {
    const { type, id, className, formik, placeholder, icon } = props;
    let formikProps = {};

    if (formik) {
        formikProps = {
            value: formik.values[id],
            onBlur: () => formik.setFieldTouched(id),
            onChange: (e) => formik.setFieldValue(id, e.target.value),
            error: formik.touched[id] && formik.errors[id],
        };
    }

    return (
        <div className={`input-wrapper ${formikProps.error ? "error" : ""}`}>
            {
                (type === 'text' || type === 'password') &&
                <div className="input-content">
                    <input
                        {...props}
                        type={type}
                        id={id}
                        name={id}
                        className={`${className} ${formik.values[id].length ? "neutral" : ""} ${icon && 'icon'}`}
                        onBlur={formikProps.onBlur}
                        onChange={formikProps.onChange}
                        value={formik.values[id]}
                        placeholder={placeholder}
                    />
                    {icon && <div className="input-icon-wrapper">
                        {icon}
                    </div>}
                </div>
            }
            {
                formikProps.error &&
                <span className="error-message">{formikProps.error}</span>
            }
        </div >
    );
};

export default InputComponent;
