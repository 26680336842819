import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ToastrContent from 'components/elements/Toastr/Toastr';
import RecoverPasswordComponent from '../components/RecoverPasswordComponent';
import { resetPasswordService } from 'services/user';

const RecoverPassword = (props) => {
    const { history } = props
    const [loading, setLoading] = useState()

    const submit = async (email) => {
        try {
            setLoading(true)
            
            const res = await resetPasswordService(email)

            toast(<ToastrContent type='success' title={'Success'} message={'Verify your email'} />, {
                progressClassName: 'toastr-progress-bar success',
            })

            history.push("/Login")
        }
        catch (e) {
            console.log('error updating', e);
            toast(<ToastrContent type='danger' title={'Error'} message={'Try again later'} />, {
                progressClassName: 'toastr-progress-bar danger',
            })
        }
        finally {

            setLoading(false)
        }
    }

    return <RecoverPasswordComponent
        {...props}
        {...{
            submit,
            loading,

        }} />
}

export default RecoverPassword;