import React from 'react';
import illustration from 'assets/images/illustration.png';
import logo from 'assets/images/turnip-logo.png';
import './Presentation.scss';

const Presentation = () => {

    return (
        <div className="claim">
            <div className="logo">
                <img src={logo} alt="main-logo" className="logo" />
            </div>
            <div className="header">
                <img src={illustration} width="100" alt="main-illustration" />
            </div>
        </div>
    )
}

export default Presentation;