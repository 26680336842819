import Button from 'components/elements/Button/Button';
import React from 'react';
import Modal from 'react-modal';
import './ConfirmationModal.scss';
import _ from 'lodash';

const ConfirmationModal = ({ open, confirmButton, denyButton, icon, title, subtitle, confirmText, loading, centerButton }) => {
    const customStyles = {
        overlay: {
            background: '#00000060',
            zIndex: 999999,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            border: 'none',
            overflow: 'auto',
            borderRadius: '27px',
            maxHeight: '70%',
            display: 'flex',
            flexDirection: 'column',
            background: 'none',
        },
    };

    return (
        <Modal isOpen={open} onRequestClose={!centerButton ? denyButton : null} style={customStyles}>
            <div className="confirmation-modal-wrapper">
                {!centerButton ? <div className="icon-wrapper">{icon}</div> : <img className="weClub-logo-image" src={icon} alt="icon" />}
                <h2>{title}</h2>
                <h3>{_.upperFirst(_.toLower(subtitle))}</h3>
                <div className={centerButton ? 'center-button-wrapper' : 'button-wreapper'}>
                    <Button loading={loading} className="confirm-button" onClick={confirmButton}>
                        {confirmText}
                    </Button>
                    {!centerButton && (
                        <button className="deny-button" onClick={denyButton}>
                            Cancel
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
