import React, { useEffect, useState } from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import './UsersComponent.scss';
import Table from "components/elements/Table/Table";
import { BiBlock, BiPlayCircle } from 'react-icons/bi'
import ConfirmationModal from "components/elements/ConfirmationModal/ConfirmationModal";
import TrashIcon from "assets/icons/TrashIcon";

const UsersComponent = (props) => {
    const { usersData, blockUser, deleteUser, loading } = props;
    const [inputValue, setInputValue] = useState()
    const [modalType, setModalType] = useState('')
    const [selectedUser, setSelectedUser] = useState()
    const [data, setData] = useState([])

    useEffect(() => {
        defaultData();
    }, [usersData])

    useEffect(() => {
        if (inputValue) {
            let newData = usersData?.filter((user) =>
                `${user.firstName} ${user.lastName}`.toLowerCase().includes(inputValue.toLowerCase()) ||
                user?.user?.email.toLowerCase().includes(inputValue.toLowerCase())
            )
                .map((user) => ({
                    key: user?.idUser,
                    name: `${user?.firstName || '-'} ${user?.lastName || ''}`,
                    email: user?.user?.email || '-',
                    actions: <Actions {...user} />,
                    isBlocked: user?.user?.isBlocked
                }))

            setData(newData);
        } else {
            defaultData()
        }
    }, [inputValue])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'key',
            width: 160,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'key',
            width: '80%',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'key',
            width: 70,
        },
    ]

    const defaultData = () => {
        const newData = usersData?.map((user) => {
            return ({
                key: user?.idUser,
                name: `${user?.firstName || '-'} ${user?.lastName || ''}`,
                email: user?.user?.email || '-',
                actions: <Actions {...user} />,
                isBlocked: user?.user?.isBlocked
            })
        })
        setData(newData);
    }

    const Actions = (user) => {
        return (
            <div className="action-buttons-wrapper">
                <div
                    onClick={() => {
                        setModalType('block')
                        setSelectedUser(user)
                    }}
                >
                    {user?.user?.isBlocked ? <BiPlayCircle /> : <BiBlock />}
                </div>
                <div
                    onClick={() => {
                        setModalType('delete')
                        setSelectedUser(user)
                    }}
                    className="trashIcon"
                >
                    <TrashIcon />
                </div>
            </div>
        );
    }

    return (
        <DashboardLayout handleInputvalue={(value) => setInputValue(value)}>
            <div className="home-wrapper">
                <h1>Users</h1>
                <div className="divider" />
                <Table data={data} columns={columns} />
            </div>
            <ConfirmationModal
                open={['block'].includes(modalType)}
                onRequestClose={() => setModalType('')}
                icon={<BiBlock />}
                title={`Are you sure you want to ${!selectedUser?.user.isBlocked ? 'block' : 'unblock'} this account?`}
                subtitle="The user will not be able to use Turnip application with their credentials."
                denyButton={() => setModalType('')}
                confirmButton={() => {
                    blockUser(selectedUser.idUser, !selectedUser?.user.isBlocked)
                    setModalType('')
                }}
                confirmText={`Yes, ${!selectedUser?.user.isBlocked ? 'block' : 'unblock'} account`}
                loading={loading}
            />
            <ConfirmationModal
                open={['delete'].includes(modalType)}
                onRequestClose={() => setModalType('')}
                icon={<TrashIcon />}
                title="Are you sure you want to delete this account?"
                subtitle="If you delete the account you will delete the user permanently"
                denyButton={() => setModalType('')}
                confirmButton={() => {
                    deleteUser(selectedUser.idUser)
                    setModalType('')
                }}
                confirmText="Yes, delete account"
                loading={loading}
            />
        </DashboardLayout>
    );
}

export default UsersComponent;
