import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
import useUser from 'stores/user';

const PrivateRoute = ({ component: Component, role, ...rest }) => {
    const user = useUser(state => state.user);
    const isLogged = !_.isEmpty(user);
    const userRole = user && user.role;
    const roleMatch = role ? (userRole === role) : true;
    
    return (
        <Route {...rest} render={props => (
            isLogged && roleMatch ?
                <Component {...props} />
                : <Redirect to="/login" />
        )} />
    );
};



export default PrivateRoute;