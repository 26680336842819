import React from 'react';

const ArrowBack = ({ color }) => {

    return (
        <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1L1 10L10 19" stroke={color || "#C482E3"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default ArrowBack;