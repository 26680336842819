import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

// Logins factory
const existingLogins = [
    {
        id: 1,
        name: 'Admin',
        email: 'admin@pitagorinesgroup.com',
        password: 'admin',
        role: 'Admin'
    },
    {
        id: 2,
        name: 'User',
        email: 'user@pitagorinesgroup.com',
        password: 'user',
        role: 'User'
    }
];

const authFactory = credentials => {
    const userExists = existingLogins.filter(existingLogin => existingLogin.email === credentials.email)[0];

    if (userExists) {
        return {
            ...userExists,
            token: "123"
        }
    } else {
        throw "Wrong credentials";
    }
}

export const loginService = async (credentials) => {
    try {
        let response = await customFetch(`${endpoints.users}/login`, {
            method: 'POST',
            bodyReq: credentials,
        });

        return { role: 'Admin', token: response.token };
    } catch (err) {
        throw err;
    }
};

export const resetPasswordService = async (email) => {
    try {
        let response = await customFetch(`${endpoints.resetpassword}/init`, {
            method: 'POST',
            bodyReq: { "email": email },
        });

        return response
    } catch (err) {
        throw err;
    }
};

export const registerService = async (credentials) => {

    try {
        let response = await customFetch(`${endpoints.user}/register`, {
            method: "POST",
            bodyReq: credentials,
        });

        if (response.error) throw response;

        const { id } = await getUserIdService(response.token);
        const userInfo = await getUserInfoService(id, response.token);

        return { ...userInfo, token: response.token }
    } catch (err) {
        throw err;
    }
};

export const getUserIdService = async (token) => {
    const userToken = token || getUserToken();
    try {
        const filters = {
            include: [
                {
                    relation: 'userSettings',
                },
                {
                    relation: 'user',
                },
            ],
        };
        let response = await customFetch(`${endpoints.user}/me?filter=${JSON.stringify(filters)}`, {
            method: 'GET',
            token: userToken,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getUserInfoService = async () => {
    const token = getUserToken()
    const filters = {
        include: [
            {
                relation: 'userSettings',
            },
            {
                relation: 'user',
            },
        ],
    };
    try {
        let response = await customFetch(`${endpoints.users}?filter=${JSON.stringify(filters)}`, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const blockerUserService = async (id, isBlocked) => {
    const token = getUserToken()

    try {
        let response = await customFetch(`${endpoints.users}/${id}/block/${isBlocked}`, {
            method: "PATCH",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const deleteUserService = async (id) => {
    const token = getUserToken()

    try {
        let response = await customFetch(`${endpoints.user}/${id}`, {
            method: "DELETE",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};