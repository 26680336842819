import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input";
import Presentation from "components/elements/Presentation/Presentation";
import './LoginComponent.scss';

const LoginComponent = (props) => {
    const { loading, submit, error, history } = props;

    const formik = useFormik({
        initialValues: { email: "", password: "" },
        validationSchema:
            yup.object().shape({
                email: yup.string().email("Invalid email format").required("Email is a required field"),
                password: yup
                    .string()
                    .required("Password is a required field"),
            }),
        onSubmit: (values) => submit(values)
    })

    return (
        <div className="loginComponent">
            <Presentation />
            <div className="formContainer">
                <div className="form">
                    <div className="title">
                        Welcome to Turnip’s <pan className="emphasis">backoffice</pan>
                    </div>
                    <div className="subtitle">
                        Sign in to your account
                    </div>

                    <Input
                        id="email"
                        placeholder="email"
                        formik={formik}
                        type="text"
                        className="formControl"
                    />

                    <Input
                        id="password"
                        placeholder="password"
                        formik={formik}
                        type="password"
                        className="formControl"
                    />

                    {error && <div className="error">Wrong credentials. Please try again.</div>}

                    <div className="forgotPassword">
                        <span
                            onClick={() => history.push('/forgot-password')}
                        >
                            Forgot password ?
                        </span>
                    </div>

                    <Button
                        loading={loading}
                        type="submit"
                        onClick={formik.handleSubmit}>
                        Login
                    </Button>
                </div>
            </div>
        </div >
    );
}

export default LoginComponent;

